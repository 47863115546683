import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import Axios from 'axios';
import { BASE_URL } from './helpers/constants';

Vue.config.productionTip = false;

const $eventBus = new Vue();
Vue.prototype.$eventBus = $eventBus;

window.axios = new Axios.create({
	baseURL: BASE_URL,
});

if (store.state.login.activeUser) {
	const user = store.state.login.activeUser;
	store.dispatch('login/fetchCenters').then(() => {
		store
			.dispatch('login/login', {
				userName: user.UserName,
				password: user.Password,
				centerId: user.CenterId,
			})
			.then(() => {
				$eventBus.$emit('show-snackbar', false, 'اهلا وسهل بك');
			})
			.catch((err) => {
				if (err.response?.status === 401) {
					store.dispatch('login/logout', { user: user });
					if (router.currentRoute.name !== 'login') router.replace({ name: 'login' });
				}
				$eventBus.$emit('show-snackbar', true, 'حدث خطأ ما');
			})
			.finally(() => {
				$eventBus.$emit('loader', false);
			});
	});
} else {
	router.replace({ name: 'login' });
}

let loggedIn = true;
router.beforeEach((to, from, next) => {
	if (Object.hasOwnProperty.call(to.query, 'desktopApp')) {
		Vue.prototype.$fromEdunix = true;
	} else {
		Vue.prototype.$fromEdunix = false;
	}

	if (from.name) loggedIn = store.state.login.activeUser;

	if (!loggedIn && to.name !== 'login') {
		next({ name: 'login' });
	} else {
		next();
	}
});

new Vue({
	router,
	store,
	vuetify,
	render: (h) => h(App),
}).$mount('#app');
